import {
  Component,
  OnInit,
  Inject,
  PLATFORM_ID,
  ViewEncapsulation,
} from "@angular/core";
import { Post } from "./redux/model";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { Router } from "@angular/router";
import { isPlatformBrowser, isPlatformServer } from "@angular/common";
import { environment } from "../environments/environment";
import * as firebase from "firebase/app";
import { Location, DOCUMENT } from "@angular/common";
import { AngularFireAnalytics } from "@angular/fire/analytics";
import { ComicService } from "./services/comic.service";
import { LocalStorageService } from "./services/local-storage.service";
import { PageMetaService, DOCUMENT_TYPE } from "./services/page-meta.service";

import { Meta, Title } from '@angular/platform-browser';
import { AppService } from "./services/app.service";

const configuration = environment.config;

interface AppState {
  post: Post;
}

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit {
  post: Observable<Post>;
  pageLoadTrace: any;
  isBrowser: boolean = false;

  constructor(
    // private store: Store<AppState>,
    private readonly router: Router,
    @Inject(PLATFORM_ID) private platform: object,
    private metaService: Meta,
    private titleService: Title,
    @Inject(DOCUMENT) private documentService: DOCUMENT_TYPE,
    private location: Location,
    private localStorageService: LocalStorageService,
    private comicService: ComicService,
    private pageMetaService: PageMetaService,
    analytics: AngularFireAnalytics,
    private appService: AppService
  ) {
    analytics.logEvent("TINYVIEW_PAGEVIEW", { url: location.path() });
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    // To set app pointing to Node/Firebase server
    this.comicService.getComicTitle();
  }

  ngOnInit() {

    console.log("-----app log start-------");
    console.log(this.platform);
    console.log(`Browser: ${isPlatformBrowser(this.platform)}`);
    console.log(`Server: ${isPlatformServer(this.platform)}`);
    console.log("-----app log end-------");

    this.isBrowser = isPlatformBrowser(this.platform) && !isPlatformServer(this.platform);

    // this.post = this.store.select("post");
    if (isPlatformBrowser(this.platform)) {
      this.openInStore();

      this.initializeConfig();
      if (!(firebase && firebase.app && firebase.apps.length)) {
        firebase.initializeApp(configuration);
        const currentUser = firebase.auth().currentUser;
        if (currentUser) {
          firebase.analytics().setUserId(currentUser.uid);
        }
      }
    } else {
      console.log('start: server Meta tags set block')
      this.pageMetaService.setMetaTags(this.documentService, this.titleService, this.metaService)
      console.log('end: server Meta tags set block')
    }
  }


  private initializeConfig() {
    // initializeCssFromJson
    this.comicService.getStylesJson().subscribe(res => {
      const styles = res.styles;
      let cssString = "";

      if (styles.desktop) {
        cssString += Object.keys(styles.desktop).reduce((acc, selector) => {
          const rules = Object.keys(styles.desktop[selector]).map((prop) => {
            return `${prop}: ${styles.desktop[selector][prop]}`;
          }).join("; ");

          return `${acc}${selector} { ${rules} }\n`;
        }, "");
      }

      if (styles.mobileWeb) {
        const mobileRules = Object.keys(styles.mobileWeb).reduce((acc, selector) => {
          const rules = Object.keys(styles.mobileWeb[selector]).map((prop) => {
            return `${prop}: ${styles.mobileWeb[selector][prop]}`;
          }).join("; ");

          return `${acc}${selector} { ${rules} }\n`;
        }, "");

        cssString += `@media (max-device-width: 991px) { ${mobileRules} }`;
      }

      const styleElement = document.createElement("style");
      styleElement.appendChild(document.createTextNode(cssString));

      document.head.appendChild(styleElement);
      if (styles.colors) {
        Object.keys(styles.colors).map((color) => {
          // camelCase to kebab case.
          const colorName = color.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase()
          document.documentElement.style.setProperty(`--${colorName}`, styles.colors[color]);
        })
      }
    })
    // Setup configs
    this.appService.getAppConfig();
    this.appService.getMaxVersionForApp().subscribe(latestWebVersion => {
      console.log('Latest Web Version:', latestWebVersion);
    });
  }

  public openInStore() {
    let tags = this.comicService.isCoverPage(true);
    if (tags == "") {
      tags = "tinyview";
    }
    const linkData = {
      campaign: "website",
      feature: "open-in-app",
      channel: "tinyview",
      tags: [tags],
      data: this.getBranchLinkData(),
    };
    if (isPlatformBrowser(this.platform) && window && window["branch"]) {
      window["branch"].link(linkData, (err, link) => {
        // location.href = link;
      });
    }
  }

  // public sendMessage(linkData, linkOptions, phone) {
  //   const callback = (err, result) => {
  //     if (err) {
  //       alert("Something went wrong.");
  //     } else {
  //       alert("Text message was sent successfully.");
  //     }
  //   };
  //   if (isPlatformBrowser(this.platform) && window && window["branch"]) {
  //     window["branch"].sendSMS(phone, linkData, linkOptions, callback);
  //   }
  // }

  // Dupe of comic service
  public getBranchLinkData(): any {
    const COMIC_API = `${environment.API_END_POINT}`;
    const switch_app_link = `${environment.SWITCH_APP_LINK}`;
    // const referredByCookie = this.cookieService.get('referred_by');
    if (isPlatformBrowser(this.platform)) {
      const referredByCookie = this.localStorageService.getItem("referred_by");
      let currentUrl = this.comicService.getCurrentUrl();
      if (currentUrl == "/") {
        currentUrl = "";
      }
      return {
        referred_by: referredByCookie,
        deeplink_path: `${COMIC_API}${currentUrl}/index.json`,
        $ios_url: "https://apps.apple.com/us/app/tinyview-comics/id1478702420",
        $android_url: `https://play.google.com/store/apps/details?id=${switch_app_link}`,
      };
    }
  }
}
