import { EventEmitter, Injectable } from '@angular/core';
import { FirebaseService } from './firebase.service';
import { API_KEYS, AUTH_TYPE, LOGIN_FLOW } from '../constants/common.constants';
import { AUTH_ERROR_MESSAGES } from '../constants/error-message.constants';
import { EventService } from './event.service';
import { isRecordNotFound } from '../utilities/error.util';
import { ApiService } from './api.service';
import { ComicService } from './comic.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private firebaseService: FirebaseService,
    private eventService: EventService,
    private comicService: ComicService,
    private apiService: ApiService,
  ) { }

  public async setNotificationSettings(notificationSettings: any): Promise<any> {
    try {
      const setNotificationSettings = await this.apiService.send(API_KEYS.SET_NOTIFICATION_SETTINGS);
      return await setNotificationSettings(notificationSettings);
    } catch (error) {
      throw new Error(error.message);
    }
  }

  public async getUserDetails(): Promise<any> {
    try {
      const userDetails = await this.comicService.getUserDetailsV2();
      if (
        userDetails
        && userDetails.data
        && userDetails.data.data
        && userDetails.data.data.status
        && userDetails.data.data.status.toLowerCase() === LOGIN_FLOW.MERGED.toLowerCase()) {
          this.eventService.showLoggedOutModalEvent.emit({ userMerged: true });
      }
      return userDetails;
    } catch (e) {
      console.log('user service - getUserDetails - catch error');
      console.log(e);

      // if (isRecordNotFound(e) && firebase.auth() && firebase.auth().currentUser) {
      //   // this.eventService.showLoggedOutModalEvent.emit({ userDeleted: true }); // we can enable this when we implement delete status in BE
      //   setTimeout(() => window.location.reload(), 1500);

      //   return;
      // } 
      // else {
      //   // This will ensure firebase user creation and restore session if exists
      //   setTimeout(() => window.location.reload(), 1500);
      // }

      throw new Error(e.message);
    }
  }

  public isSameUser(uid: string): boolean {
    return this.firebaseService.getUserID() === uid;
  }

  // need to remove 3 functions for same purpose from auth.service
  public async updateUserProfile(email?: string, phone?: string, profileData?: any) {
    try {
      const userProfile = await this.apiService.send(API_KEYS.UPDATE_USER_PROFILE);
      let reqData: any = {};

      // when only email update is required
      if (email) {
        reqData.email = email;
      }

      // when only phone update is required
      if (phone) {
        reqData.phoneNumber = phone;
      }

      // when whole profile update is required
      if (profileData) {
        reqData = profileData;
      }

      return await userProfile(reqData);
    } catch (error) {
      throw new Error(error.message);
    }
  }

  public async getUserProfileByPhoneOrEmail(authType: string, authValue: string): Promise<any> {
    try {
      const checkUser = await this.apiService.send(API_KEYS.GET_USER_PROFILE);
      let authObj: { type?: string; email?: string; phoneNumber?: string } = {};

      switch (authType) {
        case AUTH_TYPE.EMAIL:
          authObj = { type: authType, email: authValue };
          break;
        case AUTH_TYPE.PHONE:
          authObj.phoneNumber = authValue;
          break;
        default:
          throw new Error(AUTH_ERROR_MESSAGES.MISSING_EMAIL_OR_PHONE);
      }

      const userExists = await checkUser(authObj);
      return userExists;
    } catch (error) {
      throw new Error(error.message || error);
    }
  }

  // This function expects 2 cases: (!!!! USE THIS FUNCTION FROM AUTH SERVICE, NOT DIRECTLY !!!!)
  //// Case 1: { authType: 'email', authValue: 'udit@newput.com' }
  //// Case 2: { authType: 'phone', authValue: '+911112223334' }
  public async checkForExistingUser(authType: string, authValue: string): Promise<boolean> {
    try {
      const checkUser = await this.apiService.send(API_KEYS.GET_USER_PROFILE);
      let authObj: { type?: string; email?: string; phoneNumber?: string } = {};

      switch (authType) {
        case AUTH_TYPE.EMAIL:
          authObj = { type: authType, email: authValue };
          break;
        case AUTH_TYPE.PHONE:
          authObj.phoneNumber = authValue;
          break;
        default:
          throw new Error(AUTH_ERROR_MESSAGES.MISSING_EMAIL_OR_PHONE);
      }

      const userExists = await checkUser(authObj);
      return !!(userExists && userExists.data && userExists.data.length);
    } catch (error) {
      throw new Error(error.message || error);
    }
  }

  getAlertConfig() {
    // configs
    // user detail call
    // return (userobject + alerts Config)
  }
}
