<div class="mb-5">
  <!-- Table Heading Row -->
  <div class="row justify-content-center table-top table-row" [ngClass]="tableHead.class" [class.referral-top]="tableHead.class === 'referral-row'" *ngIf="tableHead.col1.value">
    <div *ngIf="tableHead.col1" class="col-auto text-center p-1" [ngClass]="tableHead.col1.class">
      <h2 class="py-2 p-bold">{{tableHead.col1 && tableHead.col1.value}}</h2>
    </div>
    <div *ngIf="tableHead.col2" class="col-auto p-1 text-center border-left" [ngClass]="tableHead.col2.class">
      <h2 class="py-2 p-bold">{{tableHead.col2 && tableHead.col2.value}} <img *ngIf="tableHead.col2.tooltip" src="../../assets/images/new-icons/icons8-information-50.png" class="mb-1 ml-1" alt="info" width="16px" height="16px" [ngbTooltip]="tableHead.col2.tooltip" tooltipClass="referral-col2-tooltip"></h2>
    </div>
    <div *ngIf="tableHead.col3" class="col-auto text-center p-1 border-left" [ngClass]="tableHead.col3.class">
      <h2 class="py-2 p-bold">{{tableHead.col3 && tableHead.col3.value}}<img *ngIf="tableHead.col3.tooltip" src="../../assets/images/new-icons/icons8-information-50.png" class="mb-1 ml-1" alt="info" width="16px" height="16px" [ngbTooltip]="tableHead.col3.tooltip" tooltipClass="custom-tooltip-series"></h2>
    </div>
    <div *ngIf="tableHead.col4" class="col-auto text-center p-1 border-left" [ngClass]="tableHead.col4.class">
      <h2 class="py-2 p-bold">{{tableHead.col4 && tableHead.col4.value}}<img *ngIf="tableHead.col4.tooltip" src="../../assets/images/new-icons/icons8-information-50.png" class="mb-1 ml-1" alt="info" width="16px" height="16px" [ngbTooltip]="tableHead.col4.tooltip" tooltipClass="custom-tooltip-pageview"></h2>
    </div>
    <div *ngIf="tableHead.col5" class="col-auto text-center p-1 border-left" [ngClass]="tableHead.col5.class">
      <h2 class="py-2 p-bold">{{tableHead.col5 && tableHead.col5.value}}</h2>
    </div>
    <div *ngIf="tableHead.col6" class="col-auto text-center p-1 border-left" [ngClass]="tableHead.col6.class">
      <h2 class="py-2 p-bold">{{tableHead.col6 && tableHead.col6.value}}<img src="../../assets/images/new-icons/icons8-information-50.png" class="mb-1 ml-1" alt="info" width="16px" height="16px" ngbTooltip="Total of revenue from pageviews and virtual gifts." tooltipClass="custom-tooltip-revenue"></h2>
    </div>
  </div>
  <!-- Table Sub Heading Row -->
  <div class="row justify-content-center table-row" [ngClass]="tableHead.class" *ngIf="isSubHeadRow">
    <div *ngIf="tableHead.col1" class="col-auto text-center p-1" [ngClass]="tableHead.col1.class">
    </div>
    <div *ngIf="tableHead.col2" class="col-auto border-left" [ngClass]="tableHead.col2.class">
      <div class="row p-0">
        <div *ngIf="tableHead.col2.subHead.subCol1" class="col-auto p-1" [ngClass]="tableHead.col2.subHead.subCol1.class"><div class="py-2 p-bold">{{tableHead.col2.subHead.subCol1.value}}</div></div>
        <div *ngIf="tableHead.col2.subHead.subCol2" class="col-auto p-1 border-left" [ngClass]="tableHead.col2.subHead.subCol2.class"><div class="py-2 p-bold">{{tableHead.col2.subHead.subCol2.value}}</div></div>
        <div *ngIf="tableHead.col2.subHead.subCol3" class="col-auto p-1 border-left" [ngClass]="tableHead.col2.subHead.subCol3.class"><div class="py-2 p-bold">{{tableHead.col2.subHead.subCol3.value}}</div></div>
        <div *ngIf="tableHead.col2.subHead.subCol4" class="col-auto p-1 border-left" [ngClass]="tableHead.col2.subHead.subCol4.class"><div class="py-2 px-2 p-bold">{{tableHead.col2.subHead.subCol4.value}}</div></div>
      </div>
    </div>
    <div *ngIf="tableHead.col3" class="col-auto p-1 border-left" [ngClass]="tableHead.col3.class">
    </div>
    <div *ngIf="tableHead.col4" class="col-auto p-1 border-left" [ngClass]="tableHead.col4.class">
    </div>
    <div *ngIf="tableHead.col5" class="col-auto p-1 border-left" [ngClass]="tableHead.col5.class">
    </div>
    <div *ngIf="tableHead.col6" class="col-auto p-1 border-left" [ngClass]="tableHead.col6.class">
    </div>
  </div>
  <!-- Total Row -->
  <div class="row justify-content-center table-row total-row" [ngClass]="tableHead.class" *ngIf="monthlyAggregateView">
    <div *ngIf="tableHead.col1" class="col-auto text-center p-1" [ngClass]="tableHead.col1.class">
      <p class="py-2">Total</p>
    </div>
    <div *ngIf="tableHead.col2" class="col-auto text-center border-left" [ngClass]="tableHead.col2.class">
      <div class="row p-0" *ngIf="tableHead.col2.subHead">
        <div *ngIf="tableHead.col2.subHead.subCol1" class="col-auto p-1 text-right" [ngClass]="tableHead.col2.subHead.subCol1.class"><p class="py-2 pr-1">{{monthlyAggregateView.col2.displayValue}}</p></div>
        <div *ngIf="tableHead.col2.subHead.subCol2" class="col-auto p-1 border-left text-right" [ngClass]="tableHead.col2.subHead.subCol2.class"><p class="py-2 pr-1">{{monthlyAggregateView.col3.displayValue}}</p></div>
        <div *ngIf="tableHead.col2.subHead.subCol3" class="col-auto p-1 border-left text-right" [ngClass]="tableHead.col2.subHead.subCol3.class"><p class="py-2 pr-1">{{monthlyAggregateView.col4.displayValue}}</p></div>
        <div *ngIf="tableHead.col2.subHead.subCol4" class="col-auto p-1 border-left text-right w-24" [ngClass]="tableHead.col2.subHead.subCol4.class"><p class="py-2 pr-1">{{monthlyAggregateView.col5.displayValue}}</p></div>
      </div>
    </div>
    <div *ngIf="tableHead.col3" class="col-auto p-1 border-left text-right" [ngClass]="tableHead.col3.class">
      <p class="py-2 pr-1">{{monthlyAggregateView.col6.displayValue}}</p>
    </div>
    <div *ngIf="tableHead.col4" class="col-auto p-1 border-left text-right" [ngClass]="tableHead.col4.class">
      <p class="py-2 pr-1">{{monthlyAggregateView.col7.displayValue}}</p>
    </div>
    <div *ngIf="tableHead.col5" class="col-auto p-1 border-left text-right" [ngClass]="tableHead.col5.class">
      <p class="py-2 pr-1">
        <span [ngClass]="{'underline': monthlyAggregateView?.virtualGifts?.value}" (click)="openModal(giftModal, monthlyAggregateView.virtualGifts)">
          {{monthlyAggregateView.col8.displayValue}}
        </span>
      </p>
    </div>
    <div *ngIf="tableHead.col6" class="col-auto p-1 border-left text-right" [ngClass]="tableHead.col6.class">
      <p class="py-2 pr-1">{{monthlyAggregateView.col9.displayValue}}</p>
    </div>
  </div>
  <!-- Day wise rows -->
  <div class="row justify-content-center table-row parent" [ngClass]="tableHead.class" *ngFor="let dailyData of calculatedDailyDataView">
    <div *ngIf="tableHead.col1" class="col-auto p-1" [ngClass]="tableHead.col1.class">
      <p class="py-2 pr-1">{{dailyData.col1.displayValue}}</p>
    </div>
    <div *ngIf="tableHead.col2" class="col-auto text-center border-left" [ngClass]="tableHead.col2.class">
      <div class="row p-0" *ngIf="tableHead.col2.subHead">
        <div *ngIf="tableHead.col2.subHead.subCol1" class="col-auto p-1 text-right" [ngClass]="tableHead.col2.subHead.subCol1.class"><p class="py-2 pr-1">{{dailyData.col2.displayValue}}</p></div>
        <div *ngIf="tableHead.col2.subHead.subCol2" class="col-auto p-1 border-left text-right" [ngClass]="tableHead.col2.subHead.subCol2.class"><p class="py-2 pr-1">{{dailyData.col3.displayValue}}</p></div>
        <div *ngIf="tableHead.col2.subHead.subCol3" class="col-auto p-1 border-left text-right" [ngClass]="tableHead.col2.subHead.subCol3.class"><p class="py-2 pr-1">{{dailyData.col4.displayValue}}</p></div>
        <div *ngIf="tableHead.col2.subHead.subCol4" class="col-auto p-1 border-left text-right w-24" [ngClass]="tableHead.col2.subHead.subCol4.class"><p class="py-2 pr-1">{{dailyData.col5.displayValue}}</p></div>
      </div>
    </div>
    <div *ngIf="tableHead.col3" class="col-auto p-1 border-left text-right" [ngClass]="tableHead.col3.class">
      <p class="py-2 pr-1">{{dailyData.col6.displayValue}}</p>
    </div>
    <div *ngIf="tableHead.col4" class="col-auto p-1 border-left text-right" [ngClass]="tableHead.col4.class">
      <p class="py-2 pr-1">{{dailyData.col7.displayValue}}</p>
    </div>
    <div *ngIf="tableHead.col5" class="col-auto p-1 border-left text-right" [ngClass]="tableHead.col5.class">
      <p class="py-2 pr-1">
        <span [ngClass]="{'underline': dailyData?.virtualGifts?.value}" (click)="openModal(giftModal, dailyData.virtualGifts)">
          {{dailyData.col8.displayValue}}
        </span>
      </p>
    </div>
    <div *ngIf="tableHead.col6" class="col-auto p-1 border-left text-right" [ngClass]="tableHead.col6.class">
      <p class="py-2 pr-1">{{dailyData.col9.displayValue}}</p>
    </div>
  </div>
</div>



<ng-template #giftModal>
<div class="p-4 m-2">
  <div class="d-flex justify-content-between">
    <h1>Virtual Gifts</h1>
    <img (click)="modalRef.hide()" src="../../../assets/images/new-icons/icons8-delete-100.png" class="cross" alt="X" width="24px" height="24px">
  </div>
  <p class="py-2 my-1">{{giftModalData.date.displayValue}}</p>
  <div class="row justify-content-center gift-table-top table-row gift">
    <div class="col-auto gift-col-name text-center p-1">
      <h2 class="py-2">Gift</h2>
    </div>
    <div class="col-auto qty-col border-left text-center p-1">
      <h2 class="py-2">Quantity</h2>
    </div>
    <div class="col-auto gift-rev-col border-left text-center p-1">
      <h2 class="py-2">Series Revenue</h2>
    </div>
  </div>
  <div class="row justify-content-center table-row gift" [class.total-row]="giftModalData.totalGiftVD.displayValue">
    <div class="col-auto gift-col-name p-1">
      <p class="py-2 pl-3 text-left">{{giftModalData.totalGiftVD.displayValue}}</p>
    </div>
    <div class="col-auto qty-col border-left p-1">
      <p class="py-2 pr-3 text-right">{{giftModalData.totalGiftVD.quantity}}</p>
    </div>
    <div class="col-auto gift-rev-col border-left p-1">
      <p class="py-2 pr-4 text-right">{{giftModalData.totalGiftVD.seriesRevenue}}</p>
    </div>
  </div>
  <div class="row justify-content-center table-row gift"  *ngFor="let gift of giftModalData.detailedGiftVD | keyvalue">
    <div class="col-auto gift-col-name p-1">
      <p class="py-2 pl-3 text-left">{{gift.value.displayValue}}</p>
    </div>
    <div class="col-auto qty-col border-left p-1">
      <p class="py-2 pr-3 text-right">{{gift.value.quantity}}</p>
    </div>
    <div class="col-auto gift-rev-col border-left p-1">
      <p class="py-2 pr-4 text-right">{{gift.value.seriesRevenue}}</p>
    </div>
  </div>

</div>
</ng-template>