<a (click)="toggleMenu.emit()">
  <img class="cross-btn m-2" src="../../assets/images/new-icons/icons8-delete-100.png"/>
</a>
<div class="mobile-nav-wrapper mt-4">
  <ul class="nav-list">
    <a class="nav-list-item user-img">
      <li class="pb-2">
        <img *ngIf="userImage" [src]="userImage" class="img-user" [class.image-border]="!!userImage"
        onError="this.src='../../assets/images/male-user-100.png'"/>
        <img *ngIf="!userImage" class="img-user" src="../../assets/images/new-icons/icons8-male-user-100.png" />
        <span class="d-flex" *ngIf="!loggedIn">
          <h1 class="user-name font mb-1">Guest User</h1>
          <span class="mt-1" *ngIf="userBadges && userBadges[0]">
            <img alt="badge-icon" width="16px" height="16px" class="badge-icon ml-1" [src]="getBadgeURL(userBadges[0])">
          </span>
        </span>
      </li>
    </a>
    <div *ngIf="!loggedIn" class="no-hover ml-2" (click)="signIn()">
      <h2 class="px-auto">Sign In</h2>
    </div>
    <p *ngIf="!loggedIn" class="ml-2 mt-1">
      New user? <span class="tv-link" (click)="signUp()">Sign Up</span>
    </p>
    <li class="user-data" *ngIf="userName || userPhoneNum">
      <span class="d-flex">
        <h1 class="user-name font mb-1">{{userName || 'x' + userPhoneNum}}</h1>
        <span class="mt-1" *ngIf="userBadges && userBadges[0]">
          <img alt="badge-icon" class="badge-icon ml-1" [src]="getBadgeURL(userBadges[0])">
        </span>
      </span>
      <p *ngIf="phoneNumber && !isIncompleteProfile" class="body-mini mb-1">{{phoneNumber}}</p>
      <p *ngIf="userEmailName && !isIncompleteProfile" class="body-mini">{{userEmailName}}</p>
    </li>
    <li (click)="navigateToProfileCompletion()" class="error-text user-data mt-2" *ngIf="isIncompleteProfile && loggedIn">
      <span class="tv-link">Complete profile</span>
    </li>
  </ul>
</div>

<div class="mobile-nav-wrapper" *ngIf="!showCreateList && !showLegalList && !showProfileList && !showFriendMenu && !showSettingsMenu && !showAlerts">
  <ul class="nav-list">
    <br>
    <ng-container>
      <a class="nav-list-item" (click)="showProfileSection()">
        <li>
          <img class="nav-icon" src="../../assets/images/new-icons/user-menu-male-100.png" alt="">
          <p class="d-inline ml-2">Account</p>
          <img *ngIf="isIncompleteProfile" src="../../assets/images/new-icons/icons8-box-important-100.png" class="icon-16 mb-1 ml-2">
          <img class="arrow" src="../../assets/images/new-icons/icons8-right-arrow-100.png"/>
        </li>
      </a>
    </ng-container>
    <a *ngIf="!isSubscribed" [routerLink]="['/tinyview/influence-points']" class="nav-list-item">
      <li>
        <img class="nav-icon" src="../../assets/images/new-icons/tinyview-influence-points-outline-100-444444.png" alt="">
        <p class="d-inline ml-2">Influence Points</p>
      </li>
    </a>
    <a (click)="showFriendSection()" class="nav-list-item">
      <li>
        <img class="nav-icon" src="./assets/images/new-icons/icons8-user-account-100-2.png" alt="Friends">
        <p class="d-inline ml-2">Friends</p> 
        <img class="arrow" src="../../assets/images/new-icons/icons8-right-arrow-100.png"/>
      </li>
    </a>
    <a (click)="showSettingsSection()" class="nav-list-item">
      <li>
        <img class="nav-icon" src="./assets/images/new-icons/icons8-settings-100.png" alt="Settings">
        <p class="d-inline ml-2">Settings</p> 
        <img class="arrow" src="../../assets/images/new-icons/icons8-right-arrow-100.png"/>
      </li>
    </a>
    <ng-container *ngIf="loggedIn && currentDevice == 'desktopBrowser'">
      <a class="nav-list-item" (click)="showCreateSection()">
        <li
          *ngIf="(canEdit && (isAdmin || tinyviewAdmin)) || (!canAdd && (isAdmin || tinyviewAdmin)) || (canEdit && (isAdmin || tinyviewAdmin) && canAdd) || (isAnalysts || tinyviewAdmin)">
          <img class="nav-icon" src="../../assets/images/pencil-icon.png" alt="">
          <p class="d-inline ml-2">Creators</p>
          <img class="arrow" src="../../assets/images/new-icons/icons8-right-arrow-100.png"/>
        </li>
      </a>
    </ng-container>
    <a class="nav-list-item" (click)="showLegalSection()">
      <li>
        <img class="nav-icon" src="../../assets/images/new-icons/icons8-goodnotes-100.png" alt="">
        <p class="d-inline ml-2">Legal</p>
        <img class="arrow" src="../../assets/images/new-icons/icons8-right-arrow-100.png"/>
      </li>
    </a>
    <a class="nav-list-item" href="mailto:support@tinyview.zendesk.com?body={{UID}}">
      <li>
        <img class="nav-icon" src="../../assets/images/new-icons/icons8-mailing-100.png" alt="">
        <p class="d-inline ml-2">Contact Us</p>
      </li>
    </a>
    <a (click)="signOut()" class="nav-list-item">
      <li *ngIf="loggedIn">
        <img class="nav-icon" src="../../assets/images/new-icons/icons8-sign-out-100-2.png" alt="">
        <p class="d-inline ml-2">Sign Out</p>
      </li>
    </a>
  </ul>
</div>
<div class="mobile-nav-wrapper" *ngIf="showProfileList">
  <ul class="nav-list">
    <a class="nav-list-item" (click)="showProfileSection()">
      <li style="padding-top: 40px;">
        <img class="nav-icon" src="../../assets/images/new-icons/back-arrow-100.png" alt="">
        <p class="d-inline ml-2">Back</p>
      </li>
    </a>
    <a *ngIf="loggedIn" class="nav-list-item" (click)="navigateToProfileCompletion()">
      <li>
        <img class="nav-icon" src="../../assets/images/new-icons/icons8-registration-100.png" alt="">
        <p class="d-inline ml-2">Edit Profile</p>
        <img *ngIf="isIncompleteProfile" src="../../assets/images/new-icons/icons8-box-important-100.png" class="icon-16 ml-2">
      </li>
    </a>
    <a class="nav-list-item" [routerLink]="[subscribeURL]">
      <li>
        <img class="nav-icon" src="../../assets/images/new-icons/icons8-renew-100.png" alt="">
        <p class="d-inline ml-2">Subscribe</p>
      </li>
    </a>
    <a *ngIf="isSubscribed" class="nav-list-item" (click)="manageSubscription()">
      <li>
        <img class="nav-icon" src="../../assets/images/new-icons/icons8-cancel-subscription-100.png" alt="">
        <p class="d-inline ml-2">Manage Subscriptions</p>
      </li>
    </a>
  </ul>
</div>
<div class="mobile-nav-wrapper" *ngIf="showCreateList">
  <ul class="nav-list">
    <a class="nav-list-item" (click)="showCreateSection()">
      <li style="padding-top: 40px;">
        <img class="nav-icon" src="../../assets/images/new-icons/back-arrow-100.png" alt="">
        <p class="d-inline ml-2">Back</p>
      </li>
    </a>
    <a class="nav-list-item" *ngIf="canAdd && (isAdmin || tinyviewAdmin)">
      <li class="nav-list-item" (click)="onEdit(true,showModal)">
        <img class="nav-icon" src="../../assets/images/new-icons/icons8-add-50.png" alt="">
        <p class="d-inline ml-2">Add Comics</p>
      </li>
      <li class="nav-list-item" *ngIf="!canAdd && (isAdmin || tinyviewAdmin)" style="opacity: 0.5;"
        (click)="onEdit(true,showModal)">
        <img class="nav-icon" src="../../assets/images/new-icons/icons8-add-50.png" alt="">
        <p class="d-inline ml-2">Add Comic</p>
      </li>
    </a>
    <a class="nav-list-item">
      <li *ngIf="canEdit && (isAdmin || tinyviewAdmin)" (click)="onEdit(false,'')">
        <img class="nav-icon" src="../../assets/images/new-icons/icons8-edit-50.png" alt="">
        <p class="d-inline ml-2">Edit Comic</p>
      </li>
    </a>
    <a class="nav-list-item">
      <li *ngIf="(isAdmin || tinyviewAdmin) && isCover && currentUrl != '/'" (click)="openLinkModal(showLinkModal)">
        <img class="nav-icon" src="../../assets/images/new-icons/icons8-add-link-50.png" alt="">
        <p class="d-inline ml-2">Add Link</p>
      </li>
    </a>
    <a class="nav-list-item" [routerLink]="['/notification', currentUrl]"
      *ngIf="canEdit && (isAdmin || tinyviewAdmin) && canAdd && !isCover">
      <li>
        <img class="nav-icon" src="../../assets/images/new-icons/icons8-add-reminder-50.png" alt="">
        <p class="d-inline ml-2">Schedule Alerts</p>
      </li>
    </a>
    <a class="nav-list-item" [routerLink]="['/dashboard']" *ngIf="(isAnalysts || tinyviewAdmin)">
      <li>
        <img class="nav-icon" src="../../assets/images/new-icons/icons8-combo-chart-50.png" alt="">
        <p class="d-inline ml-2">Main Dashboard</p>
      </li>
    </a>
    <a class="nav-list-item" [routerLink]="['/dashboard/creators']" *ngIf="(isAnalysts || tinyviewAdmin || isAdmin)">
      <li>
        <img class="nav-icon" src="../../assets/images/new-icons/icons8-combo-chart-50.png" alt="">
        <p class="d-inline ml-2">Series Dashboard</p>
      </li>
    </a>
    <a class="nav-list-item" [routerLink]="['/dashboard/referrals']" *ngIf="(isAnalysts || tinyviewAdmin || isAdmin)">
      <li>
        <img class="nav-icon" src="../../assets/images/new-icons/icons8-payroll-100.png" alt="Referral Icon">
        <p class="d-inline ml-2">Referral Revenue</p>
      </li>
    </a>
  </ul>
</div>
<div class="mobile-nav-wrapper" *ngIf="showLegalList">
  <ul class="nav-list">
    <a class="nav-list-item" (click)="showLegalSection()">
      <li style="padding-top: 40px;">
        <img class="nav-icon" src="../../assets/images/new-icons/back-arrow-100.png" alt="">
        <p class="d-inline ml-2">Back</p>
      </li>
    </a>
    <a class="nav-list-item" [routerLink]="['/terms-conditions']">
      <li>
        <img class="nav-icon" src="../../assets/images/new-icons/icons8-terms-and-conditions-100.png" alt="">
        <p class="d-inline ml-2">Terms of Use</p>
      </li>
    </a>
    <a class="nav-list-item" [routerLink]="['/privacy-policy']">
      <li>
        <img class="nav-icon" src="../../assets/images/new-icons/icons8-privacy-policy-100.png" alt="">
        <p class="d-inline ml-2">Privacy Policy</p>
      </li>
    </a>
  </ul>
</div>
<div class="mobile-nav-wrapper" *ngIf="showFriendMenu">
  <ul class="nav-list">
    <a class="nav-list-item" (click)="showFriendSection()">
      <li style="padding-top: 40px;">
        <img class="nav-icon" src="../../assets/images/new-icons/back-arrow-100.png" alt="">
        <p class="d-inline ml-2">Back</p>
      </li>
    </a>
    <a class="nav-list-item">
      <li (click)="openInviteFriendsModal()">
        <img class="nav-icon" src="./assets/images/new-icons/icons8-add-user-group-man-man-100.png" alt="Invite Friends">
        <p class="d-inline ml-2">Invite Friends</p>
      </li>
    </a>
    <a class="nav-list-item" [routerLink]="['/see-friends']">
      <li>
        <img class="nav-icon" src="./assets/images/new-icons/icons8-user-account-100-2.png" alt="See Friends">
        <p class="d-inline ml-2">See Friends</p>
      </li>
    </a>
    <a class="nav-list-item" [routerLink]="['/friend-request']">
      <li>
        <img class="nav-icon" src="./assets/images/new-icons/alarm-100.png" alt="Friend Requests">
        <p class="d-inline ml-2">Friend Requests</p>
      </li>
    </a>
  </ul>
</div>
<div class="mobile-nav-wrapper" *ngIf="showSettingsMenu">
  <ul class="nav-list">
    <a class="nav-list-item" (click)="showSettingsSection()">
      <li style="padding-top: 40px;">
        <img class="nav-icon" src="../../assets/images/new-icons/back-arrow-100.png" alt="">
        <p class="d-inline ml-2">Back</p>
      </li>
    </a>
    <a class="nav-list-item">
      <li (click)="showManageAlerts()">
        <img class="nav-icon" src="./assets/images/new-icons/alarm-100.png" alt="Manage Alerts">
        <p class="d-inline ml-2">Manage Alerts</p>
      </li>
    </a>
  </ul>
</div>

<ng-template #showModal>
  <div class="show-modal">
    <h3>Can’t Add Comic</h3>
    <p>Please create a panel with the title “Latest Comics” and try again. New comics will be added under this panel.
    </p>
    <button class="secondary-btn" aria-label="Open" (click)="modalRef.hide()">
      OK
    </button>
  </div>
</ng-template>
<ng-template #showLinkModal>
  <app-external-link [modalLinkRef]="modalLinkRef" [fromComicPannel]="false"></app-external-link>
</ng-template>