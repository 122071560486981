import { Component, EventEmitter, Inject, Input, Output, PLATFORM_ID, SimpleChanges } from "@angular/core";
import { NewComicService } from "src/app/new-comic.service";
import { isPlatformBrowser } from '@angular/common';
import { ComicService } from "src/app/services/comic.service";
import { Router } from "@angular/router";
import { BsModalService } from "ngx-bootstrap/modal";
import { isAnonymousUser } from "src/app/utilities/common.util";
import { UserService } from "src/app/services/user.service";
import { templateTypes } from "src/app/constants/common.constants";
import { ModalService } from "src/app/services/modal.service";
import { FollowModalComponent } from "../follow-modal/follow-modal.component";
import { CacheService } from "src/app/services/cache.service";
import { ToastrService } from "ngx-toastr";
import { LocalStorageService } from "src/app/services/local-storage.service";


@Component({
  selector: 'app-top-banner',
  templateUrl: './top-banner.component.html',
  styleUrls: ['./top-banner.component.scss']
})

export class TopBannerComponent {
  isMobile: boolean; 
  showLoader: boolean;
  currentUrl: string;
  tinyviewPage: boolean;
  @Input() showTo: any;
  @Input() series: any;
  @Input() hasFollowBtn: boolean;
  @Input() hasBonusPanel: boolean;
  @Input() isTopBanner: boolean;
  isLiked: boolean;
  followSeriesBanner: boolean;
  @Input() followBtnText: string;
  @Output() onPropertyChange = new EventEmitter();
  hasAnyChannel: boolean; 
  showNotGettingAlertsMessage: boolean;
  showPopUp: boolean;
  showSeriesFollowMessage: boolean;
  modalRef: any;
  showBanner: boolean;
  storyPage: boolean;
  isComicPage: boolean;
  showNotifyForGuestUser: boolean;
  isOpenAppBanner: boolean;
  bannerMessage: string;

  bannerDetails = {
    message: '',
    priority: -1,
    button: {
      action: '',
      text: ''
    }
  }
  isFollowingAlready: boolean;
  isDirectoryPage: boolean;
  isSubscribePage: boolean;
  followModalTimeOut: any = null;
  isSubscriber: boolean;

  constructor(
    private readonly comicService: ComicService,
    @Inject(PLATFORM_ID) private platform: object,
    private newComicService: NewComicService,
    private readonly router: Router,
    private modalService: BsModalService,
    private customModalService: ModalService,
    private readonly userService: UserService,
    private cacheService: CacheService,
    private toastr: ToastrService,
    private localStorageService: LocalStorageService
  ) {
    this.prepareBanner();
  }

  async prepareBanner() {
    this.isMobile = this.newComicService.isMobileView();
    this.currentUrl = this.comicService.getCurrentUrl();
    this.storyPage = this.newComicService.isStoryPage();
    this.isComicPage = this.comicService.isComicPage();
    this.isSubscribePage = this.newComicService.isSubscribePage();
    this.isDirectoryPage = this.newComicService.isDirectoryPage();
    this.tinyviewPage = this.comicService.isTinyviewPage(this.currentUrl);
    this.isSubscriber = this.localStorageService.getItem('productID') && this.localStorageService.getItem('productID').length != 0;
  }

  setIsOpenAppBanner() {
    // if (isPlatformBrowser(this.platform)) {
    //   this.isOpenAppBanner = this.cacheService.get('isOpenAppBanner') === 'false' ? false : true;
    // }
    // if (!this.isOpenAppBanner) {
      if (this.isComicPage) {
        this.isOpenAppBanner = false;
        this.setBanner(1);
      } else {
        this.setBanner(2);
      }
    // } else {
    //   this.bannerDetails.message = '';
    //   this.bannerDetails.priority = 0;
    //   this.bannerDetails.button.action = 'open-app';
    //   this.bannerDetails.button.text = 'Open App';
    //   this.showBanner = true;
    // }
  }

  setFollowSeriesBanner(flag?) {
    if (flag) return this.followSeriesBanner = true;
    if (this.followBtnText === 'Follow' && !this.showNotifyForGuestUser && !this.isMobile && this.isOpenAppBanner) {
      this.followSeriesBanner = true;
    } else {
      this.followSeriesBanner = false
    }
  }

  async checkUserComicAccess() {
    return await this.comicService.checkUserComicAccess();
  }

  async getcomicInfo() {
    const userInfo = await this.checkUserComicAccess();
    const monthlyFreeComic = userInfo.monthlyFreeComic;
    const premiumComicInfo = await this.comicService.checkPremiumComic(this.currentUrl, this.showTo);
    if (!this.isComicPage || userInfo.hasAllComicAccess || premiumComicInfo.isPremiumComic) {
      return this.setBanner(2);
    }
    const userAccessToComic = await this.comicService.checkComicReadAccess(this.currentUrl, true);
    const viewed = userAccessToComic.monthlyReadCounter.count;
    const diff = monthlyFreeComic - viewed;
    const comicLeftToView = diff > 0 ? diff : 0; 
    this.showNotifyForGuestUser = isAnonymousUser() && monthlyFreeComic;
    const comicViewed = (userAccessToComic.isRead === 2);
    if (isPlatformBrowser(this.platform)) { 
      if (comicViewed) {
        // Follow Banner
        return this.setBanner(2);
      }
    }
    if (this.showNotifyForGuestUser) {
      this.setComicLimitBanner(comicLeftToView, userAccessToComic);
    }
  }

  setComicLimitBanner(comicLeftToView: number, userAccessToComic: { isRead: number }) {
    if (comicLeftToView > 0) {
      if ((comicLeftToView%2 === 0)) {
        this.bannerDetails.message = `<span class="body-mini-bold toast-text">${comicLeftToView} comic${comicLeftToView != 1 ? 's' : ''}</span> left as a guest user`;
        this.bannerDetails.priority = 1;
        this.bannerDetails.button.action = templateTypes.comicLimit;
        this.bannerDetails.button.text = 'Sign Up';
        this.showBanner = true;
      } else {
        // Follow banner
        this.setBanner(2);
      }
    } else if (comicLeftToView == 0 && userAccessToComic.isRead !== 0) {
      this.bannerDetails.message = `<span class="body-mini-bold toast-text">Last comic</span> as a guest user`;
      this.bannerDetails.button.action = templateTypes.comicLimit;
      this.bannerDetails.button.text = 'Sign Up';
      this.bannerDetails.priority = 1;
      this.showBanner = true;
    } else if (userAccessToComic.isRead === 0) {
      this.bannerDetails.message = `<span class="body-mini-bold toast-text">0 comics</span> left as a guest user`;
      this.bannerDetails.button.action = templateTypes.comicLimit;
      this.bannerDetails.button.text = 'Sign Up';
      this.bannerDetails.priority = 1;
      this.showBanner = true;
    } else {
      this.bannerDetails.message = '';
      this.bannerDetails.button.action = '';
      this.bannerDetails.button.text = '';
      this.showBanner = false;
      // Follow Banner
      this.setBanner(2);
    }
  }

  closeBanner(priority: number) {
    this.showBanner = false;
    switch (priority) {
      case 0:
        this.closeOpenAppCard();
        this.setBanner(priority + 1);
        break;
      case 1:
        this.setBanner(priority + 1);
        break;
      case 2:
        this.setBanner(priority + 1);
        break;
      default:
        this.bannerDetails.message = '';
        this.bannerDetails.button.action = '';
        this.bannerDetails.button.text = '';
        this.bannerDetails.priority = -1;
        this.showBanner = false;
    } 
  }
  
  closeOpenAppCard() {
    if (isPlatformBrowser(this.platform)) { 
      this.isOpenAppBanner = false;
      // this.cacheService.set('isOpenAppBanner', `${this.isOpenAppBanner}`, 86400000)
    }
  }
  closeNotifyForSubscribe() {
    this.showNotifyForGuestUser = false;
  }

  public openApp(): void {
    this.comicService.openInStore('');
  }

  autoModalOpenConditionally() {  
    // To open AlreadyFollow popup, only one time on following pages
    const pageToShow = (this.isComicPage || this.isDirectoryPage || this.isSubscribePage);
    if (this.isFollowingAlready && !this.hasAnyChannel && pageToShow) {
      if (isPlatformBrowser(this.platform)) {
        const hideFollowingAlreadyModal = this.cacheService.get('hideFollowingAlreadyModal') === true ?  true : false;
        if (!hideFollowingAlreadyModal) {
          if (this.followModalTimeOut) clearTimeout(this.followModalTimeOut);
          this.followModalTimeOut = setTimeout(() => {
            this.openFollowModal();
          }, 5000);
        }
      }
    }
  }

  setBanner(priority: number) {
  
    switch (priority) {
      case 0:
        this.setIsOpenAppBanner();
        break;
      case 1:
        this.getcomicInfo();
        break;
      case 2:
        this.setFollowBanner();
        break;
      default:
        this.bannerDetails.message = '';
        this.bannerDetails.button.action = '';
        this.bannerDetails.button.text = '';
        this.bannerDetails.priority = -1;
        this.showBanner = false;
    } 
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.followBtnText && changes.followBtnText.currentValue && this.series.action && this.series.title) {
      const newValue = changes.followBtnText.currentValue;
      let pageToShowOpenApp = this.comicService.isSeriesPage() || this.comicService.isTinyviewPage(this.currentUrl) || this.comicService.isComicPage() || this.comicService.isStoryPage();
     if(this.currentUrl === '/notifications') pageToShowOpenApp = false;
      if (newValue === 'Following') {
        this.isFollowingAlready = true;
        this.followSeriesBanner = false;
        if (this.isMobile && this.isTopBanner && pageToShowOpenApp) {
          this.setBanner(0);
        } else {
          if (!this.isTopBanner) {
            this.setBanner(2)
          } else {
            (this.isComicPage && !this.tinyviewPage) ? this.setBanner(1) : this.setBanner(2);
          }
        }
      }
      if (newValue === 'Follow') {
        this.isFollowingAlready = false;
        this.followSeriesBanner = true;
        if (this.isMobile && this.isTopBanner && pageToShowOpenApp) {
          this.setBanner(0);
        } else {
          if (!this.isTopBanner) {
            this.setBanner(2)
          } else {
            (this.isComicPage && !this.tinyviewPage) ? this.setBanner(1) : this.setBanner(2);
          }
        }
      }
    }

    if (changes.series && changes.series.currentValue.action && changes.series.currentValue.title) {
      const newValue = changes.series.currentValue.action && changes.series.currentValue;
      this.series = newValue;
    }
  }

  async setFollowBanner() {
    const userData = await this.userService.getUserDetails();
    this.hasAnyChannel = this.comicService.userHasAnyChannel(userData.data);
    if (this.followSeriesBanner) {
      if (this.series.title === 'Tinyview Comics') this.series.title = 'Tinyview';
      this.bannerDetails.message = !this.tinyviewPage ? `Never miss a new comic from ${this.series.title}!` : `Never miss an update from Tinyview!`;
      this.bannerDetails.button.action = 'follow';
      this.bannerDetails.button.text = 'Follow';
      this.bannerDetails.priority = 2;
      this.showBanner = true;
    } else {
      if (!this.hasAnyChannel) {
        this.bannerDetails.message = `We’re unable to reach you`;
        this.bannerDetails.button.action = 'manage-alerts';
        this.bannerDetails.button.text = 'Manage Alerts';
        this.showBanner = true;
        // if (this.isTopBanner) this.autoModalOpenConditionally();
      } else {
        this.bannerDetails.button.action = '';
        this.bannerDetails.button.text = '';
        this.bannerDetails.message = '';
        this.bannerDetails.priority = -1;
        this.showBanner = false;
      }
    }
  }

  public async followSeries() {
    this.showLoader = true;
    if (isPlatformBrowser(this.platform)) {
      this.showLoader = true;
      this.newComicService.setFollowLoaderState({isLoading: true, isFollowing: this.followBtnText === 'Following'}); // this will ensure right state in bottom bar's follow button

      const isLikeAction = this.followBtnText === 'Follow' || false;
      const likeUrl = this.tinyviewPage
        ? `/tinyview/index.json`
        : this.storyPage
          ? `/${this.series.action}`
          : this.comicService.formatSeriesName(this.currentUrl).replace('/footer.json', '') + '/index.json';
      
      const followResult = await this.comicService.addRemoveLike(likeUrl.replace(/^\/\//, '/'), isLikeAction, false, {showToast: !this.hasBonusPanel || this.isSubscriber, series: this.series})
      if (followResult.success) {
        if (isLikeAction) {
          this.followBtnText = 'Following';
          this.isLiked = true;
          this.showBanner = false;
          this.onPropertyChange.emit({ likeCount: 1, followBtnText: this.followBtnText });
          if ((this.followBtnText === 'Following' && this.hasBonusPanel) &&  !this.isSubscriber)this.toastr.success(`Thanks for following ${this.series.title}! We have unlocked bonus panels of ${this.series.title} for you.`);
          if (this.hasBonusPanel || this.hasFollowBtn) {
            setTimeout( () => {
              window.location.reload();
            }, 3000);
          }
        }
        this.showLoader = false;
        this.newComicService.setFollowLoaderState({isLoading: false, isFollowing: this.followBtnText === 'Following'});
      }  else if (followResult.openFollowModal) {
          this.openFollowModal();
        }
    }
  }

  onAction(actionType: string) {
    switch (actionType) {
      case 'open-app':
        this.openApp();
        break;
      case 'follow':
        this.followSeries();
        break;
      case 'manage-alerts':
        this.router.navigate(['manage-alerts']);
        break;
      case templateTypes.comicLimit:
        const actionSheetConfigForNonSubscriber = {
          header: 'Get Unlimited Access',
          subHeader: `Sign Up or Sign In using email or phone for unlimited access. It's free. Also, earn 5 influence points when you Sign Up and use them to unlock bonus panels.`,
          buttons: [{
            text: 'Sign Up',
            action: 'redirectToSignUp'
          },
          {
            text: 'Sign In',
            action: 'redirectToSignIn'},
          {
            text: 'Not Now',
            action: 'closeModal',
            // class: 'cancel'
          }]
        };
        const actionSheetConfigForSubscriber = {
          header: 'Why Sign Up?',
          subHeader: `Sign Up or Sign In using email or phone so we can remember you as a subscriber. Also, allows you to use subscription benefits on any device or the Web.`,
          buttons: [{
            text: 'Sign Up',
            action: 'redirectToSignUp'
          },
          {
            text: 'Sign In',
            action: 'redirectToSignIn'},
          {
            text: 'Not Now',
            action: 'closeModal',
            // class: 'cancel'
          }]
        };
        const actionSheetConfig = this.isSubscriber ? actionSheetConfigForSubscriber : actionSheetConfigForNonSubscriber;
        this.customModalService.showActionSheetModal(actionSheetConfig);

        break;
      default:
        this.bannerDetails.message = '';
        this.bannerDetails.button.action = '';
        this.bannerDetails.button.text = '';
        this.bannerDetails.priority = -1;
        this.showBanner = false;
    } 
  }

  openFollowModal() {
    if (this.modalRef) this.modalRef.hide();
    const series = {}
    if (this.tinyviewPage) {
      series['action'] = `/index.json`;
      series['title'] = `Tinyview`;
    }

    const initialState = {
      tinyviewPage: this.tinyviewPage,
      isFollowingAlready: this.isFollowingAlready,
      series: this.tinyviewPage ? series : this.series,
      hasBonusPanel: this.hasBonusPanel
    };
    this.modalRef = this.modalService.show(FollowModalComponent, Object.assign({}, {
      initialState,
      class: 'modal-layout',
      ignoreBackdropClick: true
    }));
    this.showLoader = false;
    this.newComicService.setFollowLoaderState({isLoading: false, isFollowing: this.isFollowingAlready}); // this will ensure right state in bottom bar's follow button
  }

}