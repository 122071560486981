import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, Inject, Input, OnInit, Output, PLATFORM_ID, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { ComicService } from '../services/comic.service';
import { StoryFeedService } from '../services/story-feed.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AuthService } from '../services/auth.service';
import * as util from '../utilities/common.util';
import { BROWSER } from '../constants/common.constants';
import { LocalStorageService } from '../services/local-storage.service';
import { NewComicService } from '../new-comic.service';
import { shareConfig } from '../constants/influence.constants';
import { environment } from 'src/environments/environment';
import { SessionStorageService } from '../services/session-storage.service';
import { PanelService } from '../services/panel/panel.service';
import { AppService } from '../services/app.service';
import { DOCUMENT_TYPE, PageMetaService } from '../services/page-meta.service';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-panel-nav',
  templateUrl: './panel-nav.component.html',
  styleUrls: ['./panel-nav.component.scss']
})
export class PanelNavComponent implements OnInit, AfterViewInit {
  @Input() hideBottomSection?: boolean;
  @Input() isLiked: any;
  @Input() likeCount: any;
  @Input() viewCount: any;
  @Input() commentCount: any;
  @Input() storyID: any;
  @Input() giftItemCount: any
  @Output() doLike: EventEmitter<any> = new EventEmitter;
  @Input() giftedItems: any;
  @Input() actionType: any;
  @Input() isBottomCard: boolean;
  @Input() renderOnTop?: boolean;
  @Input() storyData: any;

  shareData = {
    title: 'Tinyview',
    text: null,
    url: null,
  };
  next: string;
  prev: string;
  home: string;
  currentDevice: string;
  currUrl: string;
  showShare: boolean = false;
  url: string;
  currentUrl: any;
  isCover: any;
  followBtnText: string;
  cachedChapterList: any;
  modalRef: BsModalRef;
  anonymousUser: any;
  giftModalRef: BsModalRef;
  likedComic: any;
  storyPage: any;
  userDetails: any;
  commentModalRef: BsModalRef;
  giftItemCount2: any;
  giftArtistModalRef: BsModalRef;
  public availableGifts;
  isCheckoutURLLoading: boolean;
  highestGift: any;
  secondHighestGift: any
  // lowestGift: any;
  shareRef: BsModalRef;
  shareConfig: { title: string; action: string; image: string; show: boolean; }[];
  isDesktop: boolean;
  isAndroid: boolean;
  isWindows: boolean;
  shareLink: any;
  isMobile: boolean;
  reactionData = [];
  ctaList = [];
  isSubscriber: any;
  isComicPage: boolean;
  isDirectoryPage: boolean;
  isSubscribePage: boolean;
  canShare: boolean;
  giftItems: any;

  constructor(
    public feedService: StoryFeedService,
    private readonly comicService: ComicService,
    private readonly router: Router,
    @Inject(PLATFORM_ID) private platform: object,
    private modalService: BsModalService,
    private localStorageService: LocalStorageService,
    public authService: AuthService,
    public newComicService: NewComicService,
    public sessionStorageService: SessionStorageService,
    public panelService: PanelService,
    private appService: AppService,
    private pageMetaService: PageMetaService,
    @Inject(DOCUMENT) private documentService: DOCUMENT_TYPE,
    private titleService: Title,
    private metaService: Meta
  ) { }

  ngOnInit() {
    this.isMobile = this.newComicService.isMobileView();
    this.isComicPage = this.comicService.isComicPage();
    this.isSubscribePage = this.newComicService.isSubscribePage();
    this.isDirectoryPage = this.newComicService.isDirectoryPage();
    this.prepareGifts();
    if (isPlatformBrowser(this.platform)) {
      this.anonymousUser = util.isAnonymousUser();
    }
    this.giftItemCount2 = { ...this.giftItemCount }
    this.currentDevice = this.comicService.getOperatingSystem();
    this.isDesktop = !!(this.currentDevice == BROWSER.DESKTOP);
    this.isAndroid = !!(this.currentDevice == BROWSER.ANDROID);
    this.isWindows = this.comicService.isWindows();
    this.isCover = this.comicService.isCoverPage();
    this.currentUrl = this.comicService.getCurrentUrl();
    this.storyPage = this.newComicService.isStoryPage();
    this.followBtnText = this.isLiked ? 'Following' : 'Follow';
    this.comicService.commentCounts$.subscribe(value => {
      if (value) {
        this.commentCount = this.commentCount - value;
      } else {
        this.commentCount++;
      }
    });

    this.shareConfig = this.comicService.getShareItems(this.isDesktop, this.isAndroid, this.isWindows, this.isComicPage);

    this.isSubscriber = this.localStorageService.getItem('productID') && this.localStorageService.getItem('productID').length != 0;

    if (isPlatformBrowser(this.platform) && window && window.navigator) {
      const windowNavigator = window.navigator;
      //@ts-ignore
      this.canShare = !!(windowNavigator && windowNavigator.share);
    }
  }

  async prepareGifts() {
    this.giftItems = await this.appService.getGifts();
    if (this.giftItemCount) {
      this.highestGift = [...this.giftItems.map(item => item.reactionName)]
        .reverse()
        .find(gift => !!this.giftItemCount[gift] && gift !== 'LIKE');
      this.secondHighestGift = [...this.giftItems.map(item => item.reactionName)]
        .reverse()
        .find(gift => !!this.giftItemCount[gift] && ![this.highestGift, 'LIKE'].includes(gift));

      if (this.highestGift === this.secondHighestGift) {
        this.secondHighestGift = null;
      }
    }
    this.availableGifts = this.giftItems;
    this.refreshReactions();
  }

  async ngAfterViewInit() {
    const data = await this.comicService.getInfluenceData('', this.storyID, false);
    // console.log('storyID', this.storyID);
    // console.log('actionURL', '');
    // console.log('data', data);
    window['branch'].link(data, (err, link) => {
      this.shareLink = link;
    });
  }

  public refreshReactions() {
    this.reactionData = [];
    this.giftItems.map(item => {
      if ((this.storyPage || this.isComicPage) && item.reactionName === 'LIKE') {
        return; // skip heart icon for comic and story page
      }
      const showGift = item.reactionName === 'LIKE' ? this.giftItemCount['LIKE'] > 0 : this.showGift(item.reactionName);
      const img = item.reactionName === 'LIKE' ? '../../assets/images/red-heart-100.png' : item.image;
      this.reactionData.push({
        reactionName: item.reactionName,
        imageSrc: img,
        show: showGift
      });
    });
  }

  public showALlComments(storyID, modal, commentModal, redirect) {
    this.ctaList = this.comicService.getCTAList();
    if (this.ctaList.length && !redirect) {
      if (this.ctaList.includes('signin')) this.openModal(modal, false);
      if (this.ctaList.includes('incompleteProfile')) this.openCommentModal(commentModal);
      if (this.ctaList.includes('subscribe')) this.openModal(modal, false);
      return;
    }
    this.userDetails = this.comicService.userDetails.data;
    if (this.anonymousUser || this.ctaList.includes('incompleteProfile')) {
      var sp = window.scrollY;
      this.comicService.scrollValue[this.comicService.getCurrentUrl()] = sp
      this.localStorageService.setItem("scroll", JSON.stringify(this.comicService.scrollValue));
      this.router.navigate(['story', storyID]);
    } else if (this.userDetails.userName && this.userDetails.gender) {
      if (isPlatformBrowser(this.platform)) {
        var sp = window.scrollY;
        this.comicService.scrollValue[this.comicService.getCurrentUrl()] = sp
        this.localStorageService.setItem("scroll", JSON.stringify(this.comicService.scrollValue));
      }
      this.router.navigate(['story', storyID]);
    }
  }

  openCommentModal(template: TemplateRef<any>) {
    this.commentModalRef = this.modalService.show(template, Object.assign({}, {
      class: "modal-layout",
      // class: 'modal-xl',
      ignoreBackdropClick: true
    }));
  }

  openGiftArtistModal(template: TemplateRef<any>) {
    const isMobile = [BROWSER.IOS, BROWSER.ANDROID].includes(this.currentDevice) ? ' mobile-modal' : '';
    this.giftArtistModalRef = this.modalService.show(template, Object.assign({}, {
      class: `modal-md${isMobile}`,
      ignoreBackdropClick: true,
      animation: !isMobile
    }));
  }

  public goToSignInPage() {
    this.router.navigate(['/edit-profile']).then(() => {
      this.commentModalRef.hide();
    })
  }

  public doLikeComic(storyID: any) {
    if (isPlatformBrowser(this.platform)) {
      let series = this.comicService.formatSeriesName(this.currentUrl).replace('/footer.json', '')
      if (this.comicService.cacheComic[series]) {
        this.cachedChapterList = this.comicService.cacheComic[series];
      } else {
        this.cachedChapterList = this.comicService.cacheComic[''];
      }
    }
    if (this.isLiked) {
      this.likeCount--;
      this.isLiked = false;
      this.giftItemCount2.LIKE--;
      let liked = this.isLiked;
      this.doLike.emit({ storyID, liked })
    } else {
      this.likeCount++;
      this.isLiked = true;
      typeof (this.giftItemCount2['LIKE']) === 'undefined' ? this.giftItemCount2['LIKE'] = 1 : this.giftItemCount2['LIKE']++;
      let liked = this.isLiked;
      this.doLike.emit({ storyID, liked })
    }
    this.refreshReactions();
    this.isCheckoutURLLoading = false;
  }

  openModal(template: TemplateRef<any>, isLike) {
    this.likedComic = isLike;
    this.modalRef = this.modalService.show(template, Object.assign({}, {
      class: 'modal-layout',
      ignoreBackdropClick: true
    }));
  }

  openGiftModal(template: TemplateRef<any>) {
    this.giftModalRef = this.modalService.show(template, Object.assign({}, {
      class: "modal-md",
      // class: 'modal-xl',
      ignoreBackdropClick: true
    }));
  }


  onShare() {
    this.shareData = {
      title: 'Tinyview',
      text: this.comicService.toTitleFormat(/[^/]*$/.exec(this.currentUrl.replace('/index.json', ''))[0]),
      url: 'https://tinyview.com' + this.currentUrl.replace('/index.json', ''),
    };
    if (this.currentDevice == BROWSER.ANDROID || this.currentDevice == BROWSER.IOS) {
      this.share();
    } else {
      this.showShare = !this.showShare;
    }
  }

  async share() {
    let newVariable: any;
    if (isPlatformBrowser(this.platform) && window && window.navigator) {
      newVariable = window.navigator;
      if (newVariable && newVariable.share) {
        try {
          await newVariable.share(this.shareData);
        } catch (err) {
        }
      }
    }
  }

  public shareFacebook(): void {
    if (isPlatformBrowser(this.platform)) {
      const url = encodeURI(this.shareData.url);
      const shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${url}&utmSource=facebook&utmContent=referral&utmTerm=topbar&referrer=facebook`;
      this.popUpUrl(shareUrl);
    }
  }

  public shareTwitter(): void {
    if (isPlatformBrowser(this.platform)) {
      const url = encodeURI(this.shareData.url);
      const shareUrl = `https://www.twitter.com/intent/tweet?url=${url}&via=TinyviewComics&utmSource=twitter&utmContent=referral&utmTerm=topbar&referrer=twitter`;
      this.popUpUrl(shareUrl);
    }
  }

  private popUpUrl(url: string): void {
    const w = 600;
    const h = 500;
    const title = "Share Tinyview";
    const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
    const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;
    const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
    const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;
    const systemZoom = width / window.screen.availWidth;
    const left = (width - w) / 2 / systemZoom + dualScreenLeft
    const top = (height - h) / 2 / systemZoom + dualScreenTop
    const newWindow = window.open(url, title,
      `
      scrollbars=yes,
      width=${w / systemZoom}, 
      height=${h / systemZoom}, 
      top=${top}, 
      left=${left}
      `
    )
    if (window.focus) newWindow.focus();
  }

  public addReaction(reactionType: string, productID: string, value: number) {
    if (this.alreadyGifted({ reactionName: reactionType })) {
      return;
    }
    this.isCheckoutURLLoading = true;
    if (reactionType === 'LIKE') {
      this.doLikeComic(this.storyID);
      this.giftArtistModalRef.hide();
    } else {
      this.comicService.getCheckoutURL(productID, this.storyID, reactionType, value).then((res) => {
        // this.isCheckoutURLLoading = false;
        const url = res.data.data.url;
        if (url) {
          window.open(url, "_self");
        }
        this.giftArtistModalRef.hide();
      })
        .catch(err => {
          this.isCheckoutURLLoading = false;
          this.giftArtistModalRef.hide();
        });
    }
  }

  public getImgUrl(gift: any): string {
    if (gift.reactionName === 'LIKE') { // According to COM-2503, we need to show filled when it is not liked and vice versa
      return !this.isLiked ? '../../assets/images/heart-outline-100.png?v=1' : '../../assets/images/heart-outline-filled-100.png?v=1';
    }
    return gift.image;
  }

  public getGiftTitle(gift: any): string {
    if (gift.reactionName === 'LIKE') {
      return this.isLiked ? 'Unlike' : 'Like';
    }
    return gift.title;
  }

  public alreadyGifted(gift: any): boolean {
    if (this.isCheckoutURLLoading) {
      return true;
    }
    if ((this.actionType === 'website' && gift.reactionName !== 'LIKE') || (this.storyData && this.storyData.refType === 'SENT' && gift.reactionName !== 'LIKE')) {
      return true;
    }
    if (this.giftedItems && this.giftedItems[gift.reactionName]) {
      return (gift.reactionName === 'LIKE') ? false : true;
    }
    return false;
  }

  public showGift(gift: string): boolean {
    if (!gift) {
      return false;
    }
    if (this.highestGift === gift || this.secondHighestGift === gift) {
      return true
    };
    return false;
  }

  public manualShare(share) {
    this.comicService.shareConditions(this.shareLink, share.action, this.isDesktop, this.isAndroid);
    // this.comicService.manualShare('', this.storyID, share, this.isDesktop, this.isAndroid);
    this.shareRef && this.shareRef.hide();
  }

  async openShareModal(template: TemplateRef<any>) {
    this.pageMetaService.setMetaTags(this.documentService, this.titleService, this.metaService);

    let comicAction = (this.storyData && this.storyData.action) || (['/tinyview/comic-series-directory', '/tinyview/subscribe'].includes(this.currentUrl) ? `${this.currentUrl}/index.json` : this.currentUrl);
    let isWebsite: boolean = this.actionType === 'website';

    // we want tv options now on comic page
    // if (this.isComicPage && !this.isSubscribePage && this.canShare) {
    //   this.manualShare(shareConfig[6]);
    //   return;
    // }

    if (isWebsite) {
      comicAction = `/story/${this.storyID}/`;
    }

    if (this.storyData && (this.storyData.seriesImage || this.storyData.comicImage)) {
      let metaTagsData = {};
      let image = this.storyData.comicImage || this.storyData.seriesImage;
      image = this.panelService.getImgUrl(image, this.currentUrl);
      metaTagsData['og:title'] = this.storyData.comicTitle || this.storyData.seriesTitle;
      metaTagsData['og:description'] = (this.storyData.comments || this.storyData.comicDescription);
      metaTagsData['og:image'] = image.indexOf(environment.API_END_POINT) > -1 ? image : `${environment.API_END_POINT}${image}`;

      // TODO(shashank): make it through params
      this.sessionStorageService.setItem('metaTagsInfo', metaTagsData);
    }

    const data = await this.comicService.getInfluenceData(comicAction, this.storyID, isWebsite);
    // console.log('storyID', this.storyID);
    // console.log('actionURL', '');
    // console.log('data', data);
    window['branch'].link(data, (err, link) => {
      this.shareLink = link;
      const isMobile = [BROWSER.IOS, BROWSER.ANDROID].includes(this.currentDevice) ? ' mobile-modal' : '';
      this.shareRef = this.modalService.show(template, Object.assign({}, {
        class: `modal-md${isMobile}`,
        ignoreBackdropClick: false,
        animation: !isMobile
      }));
    });
  }
}
