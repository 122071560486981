import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NewComicService } from "src/app/new-comic.service";
import { environment } from "src/environments/environment";

const COMIC_API = `${environment.API_END_POINT}`
@Component({
  selector: 'app-comic-rating',
  templateUrl: './comic-rating.component.html',
  styleUrls: ['./comic-rating.component.scss']
})
export class ComicRatingComponent implements OnInit {
  @Input() comic;
  @Output() showRead = new EventEmitter();
  buttonsArray = [];
  showDoubleTick: boolean;
  isSubscribePage: boolean;

  constructor(
    private readonly newComicService: NewComicService
  ) {
    this.isSubscribePage = this.newComicService.isSubscribePage();
  }

  ngOnInit() {
    if ((this.comic['rating'] || this.comic["show-to"]) && !this.isSubscribePage) {
      if ((this.comic["show-to"].key !== '') && (this.comic["show-to"].key !== 'everyone')) {
        
        this.buttonsArray.push(   {
          icon: this.comic["show-to"].icon ? `${COMIC_API}${this.comic["show-to"].icon}` : '',
          label: this.comic["show-to"].label,
          tooltip: this.comic["show-to"].tooltip
        })
      }

      if ((this.comic["rating"].key !== '') && (this.comic["rating"].key !== 'all-ages')) {
        this.buttonsArray.push({
          icon: this.comic["rating"].icon ? `${COMIC_API}/${this.comic["rating"].icon}` : '',
          label: this.comic["rating"].label,
          tooltip: this.comic["rating"].tooltip,
        })
      }
      if (this.comic && this.comic.isRead) {
        if (this.buttonsArray.length !== 0 && this.buttonsArray.some(btn => btn.label !== '')) {
          this.showDoubleTick = true;
          this.showRead.emit({show: false})
        } else {
          this.showRead.emit({show: true})
        }
      }
    }
  }
}
